import React, { useEffect, useState } from "react";
import Api from "./components/api/api";
import Login from "./components/Login";
import Loading from "./components/Loading";
import Sidebar from "./components/Sidebar";
import Lock from "./components/Lock";
import Products from "./components/menu/Products";
import Cart from "./components/cart/Cart";
import Header from "./components/Header";
import PopUp from "./components/PopUp";
import Settings from "./components/Settings";
import Confirm from "./components/confirm";
import jwt_decode from "jwt-decode";
import moment from "moment/moment";
import "./css/App.css";
import "./css/Login.css";
import "./css/Header.css";
import "./css/Sidebar.css";
import "./css/Cart.css";
import "./css/Loading.css";
import "./css/Lock.css";
import "./css/Products.css";
import "./css/PopUp.css";
import "./css/Settings.css";
import "./css/variantsPopUp.css";
import "./css/TablesList.css";
import "./css/HeaderRight.css";
import "./css/Footer.css";
import OrdersList from "./components/OrdersList";
import AddressPopUp from "./components/AddressPopUp";
import AddressForm from "./components/AddressForm";
import Socket from "./Socket";
import { io } from "socket.io-client";
import TablesList from "./components/TablesList";
import TablePopUp from "./components/TablePopUp";
import SellsList from "./components/SellsList";
import Footer from "./components/Footer";
import HeaderRight from "./components/HeaderRight";
import DiscountsPopup from "./components/cart/DiscountsPopup";
import EditProductPopup from "./components/cart/EditProductPopup";
import SplitPopup from "./components/cart/SplitPopup";
import OpenTablePopup from "./components/OpenTablePopup";
import TableSelector from "./components/TableSelector";
import InvoicePopup from "./components/invoice/InvoicePopup";
import InvoiceFormPopup from "./components/invoice/InvoiceFormPopup";
import ReportsPopUp from "./components/ReportsPopUp";
import PosPaymentPopup from "./components/cart/PosPaymentPopup";

export const AppContext = React.createContext();

let socketUrl = getSocketUrl();
console.log("socketUrl", socketUrl);
const socket = io(socketUrl);

function App() {
	const [config, setConfig] = useState(
		localStorage.getItem("pos-config")
			? JSON.parse(localStorage.getItem("pos-config"))
			: {
					mode: "remote",
					product_button_height: "fixed",
					confirm_order: 0,
					fiscal_printer_id: null,
					keep_open_after_checkout: 0,
					check_fiscal_closure: 0,
			  }
	);
	const [lock, setLock] = useState(false);
	const [loading, setLoading] = useState(false);
	const [apiSettings, setApiSettings] = useState(false);
	const [settingsVisible, setSettingsVisible] = useState(false);
	const [user, setUser] = useState(false);
	const [shops, setShops] = useState(false);
	const [shop, setShop] = useState(
		localStorage.getItem("pos-shop") ? JSON.parse(localStorage.getItem("pos-shop")) : false
	);
	const [lists, setLists] = useState(false);
	const [list, setList] = useState(
		localStorage.getItem("pos-list") ? JSON.parse(localStorage.getItem("pos-list")) : false
	);
	const [menu, setMenu] = useState(false);
	const [filteredCategories, setFilteredCategories] = useState(false);
	const [category, setCategory] = useState(false);
	const [categoryPos, setCategoryPos] = useState(false);
	const [cart, setCart] = useState(false);
	const [productionCenters, setProductionCenters] = useState(false);
	const [rooms, setRooms] = useState(false);
	const [tables, setTables] = useState(false);
	const [popup, setPopup] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const [iframeMode, setIframeMode] = useState(false);
	const [activeFlow, setActiveFlow] = useState(1);
	const [ordersListVisible, setOrdersListVisible] = useState(false);
	const [tablesListVisible, setTablesListVisible] = useState(false);
	const [sellsListVisible, setSellsListVisible] = useState(false);
	const [orders, setOrders] = useState([]);
	const [sells, setSells] = useState([]);
	const [orderByIdLoaded, setOrderByIdLoaded] = useState(false);
	const [addressPopUpVisible, setAddressPopUpVisible] = useState(false);
	const [tablePopup, setTablePopup] = useState(false);
	const [addressFormPopUpVisible, setAddressFormPopUpVisible] = useState(false);
	const [discountsPopup, setDiscountsPopup] = useState(false);
	const [editingProduct, setEditingProduct] = useState(false);
	const [splitPopup, setSplitPopup] = useState(false);
	const [posCategories, setPosCategories] = useState([]);
	const [mode, setMode] = useState(localStorage.getItem("pos-menu-mode") || 1);
	const [edited, setEdited] = useState(false);
	const [fiscalPrinters, setFiscalPrinters] = useState([]);
	const [filterDate, setFilterDate] = useState("");
	const [cloudStatus, setCloudStatus] = useState(false);
	const [openTablePopupVisible, setOpenTablePopupVisible] = useState(false);
	const [table, setTable] = useState(false);
	const [tableSelectorVisible, setTableSelectorVisible] = useState(false);
	const [invoicePopup, setInvoicePopup] = useState(false);
	const [invoiceFormPopup, setInvoiceFormPopup] = useState(false);
	const [deliveryPrice, setDeliveryPrice] = useState(0);
	const [total, setTotal] = useState(false);
	const [round, setRound] = useState(0);
	const [reportsPopUpVisible, setReportsPopUpVisible] = useState(false);
	const [posPaymentPopup, setPosPaymentPopup] = useState(false);
	const delivery_types = [
		{ id: "shop", name: "Sala" },
		{ id: "takeaway", name: "Asporto" },
		{ id: "delivery", name: "Consegna a domicilio" },
	];
	const queryParams = new URLSearchParams(window.location.search);

	useEffect(() => {
		init();
	}, []);

	useEffect(() => {
		console.log("cloudStatus set to", cloudStatus);
	}, [cloudStatus]);

	useEffect(() => {
		console.log("cart set to", cart);
		if (cart && cart.list_id) setList(lists.find((l) => l.id == cart.list_id));
	}, [cart]);

	useEffect(() => {
		if (config) localStorage.setItem("pos-config", JSON.stringify(config));
	}, [config]);

	useEffect(() => {
		if (user && shops && lists) init_cart();
	}, [user, shops, lists]);

	useEffect(() => {
		if (user) loadData();
	}, [user]);

	useEffect(() => {
		console.log("orderByIdLoaded", orderByIdLoaded);
		if (menu && !orderByIdLoaded) loadOrderById(queryParams.get("order_id"));
	}, [menu]);

	useEffect(() => {
		if (apiSettings) {
			loadOrders();
			loadSells();
		}
	}, [shop, apiSettings, filterDate]);

	useEffect(() => {
		localStorage.setItem("pos-menu-mode", mode);
	}, [mode]);

	useEffect(() => {
		if (apiSettings?.mandant?.fiscal_printers == 1 && config.check_fiscal_closure == 1)
			window.setInterval(checkDailyClosures, 5000);
		return () => window.clearInterval(checkDailyClosures);
	}, [fiscalPrinters]);

	async function init() {
		console.log("init");
		setLock(true);
		Api.setBaseUrl(config);
		await restore_user();
		setLock(false);
	}

	async function loadData() {
		console.log("loadData");
		setLock(true);
		await load_settings();
		await load_shops();
		await load_production_centers();
		await load_rooms();
		await load_tables();
		await load_lists();
		await load_pos_categories();
		await load_menu();
		await loadFiscalPrinters();
		setLock(false);
	}

	async function loadOrderById(orderId) {
		if (!orderId) return;
		console.log("loadOrderById", orderId);
		setLoading(true);
		const response = await Api.getProtected("/orders/get/" + orderId + "/");
		if (response.success == 0) alert(response.error);
		else openOrder(response.data);
		setLoading(false);
		setOrderByIdLoaded(true);
	}

	async function restore_user() {
		if (queryParams.get("user_id")) {
			setIframeMode(true);
			const id = queryParams.get("user_id");
			const refresh = await Api.autoLogin(id);
			if (refresh.success == 0) alert(refresh.error);
			else {
				localStorage.setItem("pos-user", refresh.token);
				const decoded = jwt_decode(refresh.token);
				const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
				if (response.success == 0) alert(response.error);
				else setUser(response.data);
			}
		} else {
			const token = localStorage.getItem("pos-user");
			if (token) {
				const refresh = await Api.refreshToken(token);
				if (refresh.success == 0) alert(refresh.error);
				else {
					localStorage.setItem("pos-user", refresh.token);
					const decoded = jwt_decode(refresh.token);
					const response = await Api.getProtected("/users/get/" + decoded.user_id + "/");
					if (response.success == 0) alert(response.error);
					else setUser(response.data);
				}
			}
		}
	}

	async function load_settings() {
		const response = await Api.post("/settings2/get/", {
			section: ["global", "orders", "google", "pos", "menu_api", "customers"],
		});
		if (response.success == 0) alert(response.error);
		else setApiSettings(response.data);
	}

	async function load_shops() {
		const response = await Api.post("/pos2/shops/", { user_id: user.id });
		if (response.success == 0) alert(response.error);
		setShops(response.rows);
		if (response.rows.length == 1) setShop(response.rows[0]);
		else if (shop) {
			const newData = response.rows.find((s) => s.id == shop.id);
			if (newData) setShop(newData);
		}
	}

	async function load_production_centers() {
		const response = await Api.get("/pos2/production_centers/");
		if (response.success == 0) alert(response.error);
		setProductionCenters(response.rows);
	}

	async function load_rooms() {
		const response = await Api.get("/pos2/rooms/");
		if (response.success == 0) alert(response.error);
		else setRooms(response.rows);
	}

	async function load_tables() {
		console.log("load_tables");
		const response = await Api.post("/pos2/tables/");
		if (response.success == 0) alert(response.error);
		else setTables(response.rows);
	}

	async function load_lists() {
		const response = await Api.post("/lists/list/", { orderby: "position" });
		if (response.success == 0) alert(response.error);
		else setLists(response.rows);
	}

	async function loadFiscalPrinters() {
		const response = await Api.postProtected("/fiscal_printers/list/", {
			src: [{ name: "active", value: 1, compare: "equal" }],
		});
		if (response.success == 0) alert(response.error);
		else setFiscalPrinters(response.rows);
	}

	async function load_pos_categories() {
		const response = await Api.postProtected("/pos_categories/list/", {
			orderby: "position",
			src: [{ name: "active", value: 1, compare: "equal" }],
		});
		if (response.success == 0) alert(response.error);
		else {
			setPosCategories(response.rows);
			if (!categoryPos && response.rows.length > 0) setCategoryPos(response.rows[0]);
		}
	}

	async function load_menu() {
		console.log("load_menu");
		const response = await Api.get("/menu/courses/");
		if (response.success == 0) alert(response.error);
		else setMenu(response.rows);
	}

	async function loadOrders() {
		console.log("loadOrders");
		if (!shop) return;
		let request = {
			orderby: "delivery_date DESC",
			per_pag: 50,
			src: [
				{
					name: "shop_id",
					value: shop.id,
					compare: "equal",
				},
				{
					name: "status",
					value: "archived",
					compare: "not_archived",
				},
				{
					name: "bill",
					value: 0,
					compare: "equal",
				},
			],
		};
		const res = await Api.postProtected("/orders/list/", request);
		if (res.success == 0) alert(res.error);
		else {
			setLoading(false);
			setOrders(res.rows);
		}
	}

	async function loadSells() {
		if (apiSettings.mandant.fiscal_printers == 1) {
			console.log("loadSells");
			let request = {
				per_pag: 20,
				orderby: "delivery_date DESC",
				src: [
					{
						name: "bill",
						value: 3,
						compare: "equal",
					},
					{
						name: "delivery_date",
						value: moment().format("YYYY-MM-DD"),
						compare: "date",
					},
					{
						name: "status",
						value: "not",
						compare: "not_archived",
					},
				],
			};
			if (shop) {
				request.src.push({
					name: "shop_id",
					value: shop.id,
					compare: "equal",
				});
			}
			const res = await Api.postProtected("/orders/list/", request);
			if (res.success == 0) alert(res.error);
			else setSells(res.rows);
		}
	}

	const editTable = async (id, request) => {
		console.log("editTable", id, request);
		const res = await Api.postProtected("/tables/edit/" + id + "/", request);
		if (res.success == 0) alert(res.error);
		else return res.data;
	};

	function init_cart(data = {}) {
		const delivery_type = JSON.parse(localStorage.getItem("pos-delivery_type"));

		setEdited(false);

		const newCart = {
			...{
				order_id: false,
				covers: 1,
				origin: "pos",
				status: "confirmed",
				customer: false,
				address: null,
				delivery_date: null,
				delivery_time_range: null,
				shop_id: shop ? shop.id : null,
				list_id: list ? list.id : null,
				room_id: null,
				table: null,
				delivery_type: delivery_type ? delivery_type : { id: "shop", name: "Sala" },
				products: [],
				discounts: [],
				credit_total: 0,
				user_id: user.id,
				invoice: 0,
			},
			...data,
		};

		delete newCart["$loki"];

		setCart(newCart);
	}

	function editCart2(data) {
		console.log("editCart2", data);
		let nCart = { ...cart, ...data };
		setCart(nCart);
		//setEdited(true);
	}

	function add_product(product, price = false) {
		let nCart = structuredClone(cart);
		const i = cart.products.findIndex((p) => {
			let same = false;
			if (p.id == product.id && p.flow == activeFlow) same = true;
			console.groupCollapsed();
			for (const vc1 of p.variants_categories) {
				for (const vc2 of product.variants_categories) {
					if (vc1.id == vc2.id) {
						console.group(vc1.name);
						for (const v1 of vc1.variants) {
							for (const v2 of vc2.variants) {
								if (v1.id == v2.id) {
									console.log(v1.name);
									console.log(v1.qty, v2.qty);
									if (v1.qty != v2.qty) same = false;
								}
							}
						}
						for (const v1 of vc1.variants2) {
							for (const v2 of vc2.variants2) {
								if (v1.id == v2.id) {
									console.log(v1.name);
									console.log(v1.qty, v2.qty);
									if (v1.qty != v2.qty) same = false;
								}
							}
						}
						for (const v1 of vc1.products) {
							for (const v2 of vc2.products) {
								if (v1.id == v2.id) {
									console.log(v1.name);
									console.log(v1.qty, v2.qty);
									if (v1.qty != v2.qty) same = false;
								}
							}
						}
						console.groupEnd();
					}
				}
			}
			console.groupEnd();
			for (const i1 of p.ingredients) {
				for (const i2 of product.ingredients) {
					if (i1.id == i2.id) {
						if (i1.removed != i2.removed) same = false;
					}
				}
			}
			return same;
		});
		console.log(i);
		if (i >= 0) nCart.products[i].qty++;
		else {
			let newP = structuredClone(product);
			newP.qty = 1;
			newP.flow = activeFlow;
			if (price) newP.price = price;
			nCart.products.push(newP);
		}
		console.log(nCart);
		setEdited(true);
		setCart(nCart);
	}

	async function openOrder(order) {
		console.log(order);
		setEdited(false);
		setList(order.list_id || false);
		setCart({
			order_id: order.id,
			$loki: order["$loki"],
			covers: order.covers,
			origin: order.origin,
			status: order.status,
			customer:
				order.customer_id || order.email
					? {
							id: order.customer_id,
							name: order.name,
							surname: order.surname,
							email: order.email,
							email: order.mobile,
					  }
					: false,
			address: order.delivery_address
				? {
						...order.delivery_address,
						...{
							polygon_id: order.polygon_id,
							delivery_price: order.delivery_price,
							delivery_free_min: 100,
						},
				  }
				: null,
			delivery_date: order.delivery_date,
			delivery_time_range: order.delivery_time_range,
			shop_id: order.shop_id,
			list_id: order.list_id,
			room_id: order.room_id,
			table: order.table_id ? order.table : null,
			delivery_type: delivery_types.filter((dt) => dt.id == order.delivery_type)[0],
			products: formatProducts(order.products),
			discounts: formatDiscounts(order.discounts),
			user_id: order.user_id,
			payment_method: order.payment_method,
			payment_method_id: order.payment_method_id,
			credit_total: order.credit_total,
		});
	}

	function formatProducts(products) {
		let res = [];
		for (const p of products) {
			const el = {
				id: p.product_id,
				item_id: p.id,
				uuid: p.uuid,
				product_id: p.product_id,
				name: p.name,
				qty: p.qty,
				price: p.price,
				variants_categories: formatVariantsCategories(p.product_id, p.variants, p.products),
				flow: p.flow ? p.flow : 1,
				printed: [],
				ingredients: [],
			};
			res.push(el);
		}
		return res;
	}

	function formatVariantsCategories(productId, variants, subproducts) {
		let originaProduct = false;
		menu.forEach((course) =>
			course.categories.forEach((category) =>
				category.products.forEach((product) => {
					if (product.id == productId) originaProduct = product;
				})
			)
		);
		//console.log("originaProduct", originaProduct);
		if (originaProduct.variants_categories) {
			const variantsCategories = structuredClone(originaProduct.variants_categories);
			variantsCategories.forEach((variants_category) => {
				let selected = 0;
				variants_category.variants.forEach((variant) => {
					variant.qty = 0;
					variants.forEach((v) => {
						if (
							v.variant_category_id == variants_category.id &&
							v.variant_id == variant.id
						) {
							variant.qty = v.qty;
							selected++;
						}
					});
				});
				variants_category.variants2.forEach((variant) => {
					variant.qty = 0;
					variants.forEach((v) => {
						if (
							v.variant_category_id == variants_category.id &&
							v.variant_id == variant.id
						) {
							variant.qty = v.qty;
							selected++;
						}
					});
				});
				variants_category.products.forEach((subproduct) => {
					subproduct.qty = 0;
					subproducts.forEach((s) => {
						if (
							s.variant_category_id == variants_category.id &&
							s.product_id == subproduct.id
						) {
							subproduct.qty = s.qty;
							selected++;
						}
					});
				});
				variants_category.selected = selected;
			});
			return variantsCategories;
		} else return [];
	}

	function formatDiscounts(discounts) {
		let res = [];
		for (const d of discounts) {
			const el = {
				...d,
				value: d.value ? parseFloat(d.value) : 0,
				price: d.price ? parseFloat(d.price) : 0,
			};
			res.push(el);
		}
		return res;
	}

	const checkout = async (
		reopen = false,
		payment_method = false,
		print_bill = false,
		requestPosPayment = false,
		data = {}
	) => {
		console.log("checkout");
		setLoading(true);
		let order = { ...cart, products: [], ...data };
		if (payment_method) order.payment_method_id = payment_method.id;
		if (!order.delivery_date) order.delivery_date = moment().format("YYYY-MM-DD HH:mm:ss");
		if (order.table?.id) order.table_id = order.table.id;
		if (requestPosPayment) order.status = "awaiting_payment";
		if (order.customer) {
			order.customer_id = order.customer.id;
			order.name = order.customer.name;
			order.surname = order.customer.surname;
			order.area_code = order.customer.area_code;
			order.mobile = order.customer.mobile;
			order.email = order.customer.email;
		}
		order.delivery_type = order.delivery_type.id;
		order.polygon_id = order.address?.polygon_id ? order.address.polygon_id : null;
		order.delivery_price = deliveryPrice;
		order.round = round;
		order.total = total;
		order.fiscal_printer_id = config.fiscal_printer_id || null;
		delete order.table;
		delete order.customer;
		delete order.order_id;

		for (const p of cart.products) {
			order.products.push({
				product_id: p.id,
				name: p.name,
				qty: p.qty,
				price: p.price,
				notes: prepareIngredients(p.ingredients) + (p.notes ? p.notes : ""),
				flow: p.flow,
				size: p.size,
				variants: prepareVariants(p.variants_categories),
				products: prepareProducts(p.variants_categories),
			});
		}

		if (print_bill) {
			order.bill = 1;
		}

		const res = await Api.postProtected("/orders/add/", order);
		setLoading(false);
		if (res.success == 1) {
			if (requestPosPayment) {
				setPosPaymentPopup(res.data);
			} else {
				init_cart();
				window.parent.postMessage("close", "*");
				if (reopen) openOrder(res.data);
			}
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const updateOrderFromCart = async (
		payment_method = false,
		print_bill = false,
		print_prebill = false,
		requestPosPayment = false
	) => {
		console.log("updateOrderFromCart");

		let order = {
			delivery_type: cart.delivery_type.id,
			delivery_date: cart.delivery_date ?? moment().format("YYYY-MM-DD HH:mm:ss"),
			delivery_time_range: cart.delivery_time_range,
			delivery_price: deliveryPrice,
			table_id: cart.table?.id ? cart.table.id : null,
			covers: cart.covers,
			total: total,
			notes: cart.notes,
			products: [],
			discounts: cart.discounts,
			invoice: cart.invoice,
			billing_address: cart.billing_address || null,
		};

		if (payment_method) order.payment_method_id = payment_method.id;
		if (requestPosPayment) order.status = "awaiting_payment";

		if (config.confirm_order == 1 && cart.status == "unconfirmed") order.status = "confirmed";

		if (cart.customer) {
			order.customer_id = cart.customer.id;
			order.name = cart.customer.name;
			order.surname = cart.customer.surname;
			order.area_code = cart.customer.area_code;
			order.mobile = cart.customer.mobile;
			order.email = cart.customer.email;
		}

		if (cart.address) order.address = cart.address;

		for (const p of cart.products) {
			order.products.push({
				id: p.item_id,
				product_id: p.id,
				name: p.name,
				qty: p.qty,
				price: p.price,
				notes: p.notes,
				flow: p.flow,
				variants: prepareVariants(p.variants_categories),
				products: prepareProducts(p.variants_categories),
			});
		}

		if (print_bill) {
			order.bill = 1;
		}

		if (print_prebill) {
			order.prebill_request = 1;
			order.prebill_printed = 0;
		}

		const id = config.mode == "local" ? cart["$loki"] : cart.order_id;
		setLoading(true);
		const res = await Api.postProtected("/orders/edit/" + id + "/", order);
		setLoading(false);
		if (res.success == 1) {
			if (requestPosPayment) {
				setPosPaymentPopup(res.data);
			} else {
				init_cart();
				window.parent.postMessage("close", "*");
			}
		} else
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const prepareVariants = (vcs) => {
		let res = [];
		for (const vc of vcs) {
			for (const v of vc.variants) {
				if (v.qty && v.qty > 0)
					res.push({
						variant_category_id: vc.id,
						variant_id: v.id,
						name: v.name,
						qty: v.qty,
						price: v.price,
					});
			}
			for (const v of vc.variants2) {
				if (v.qty && v.qty > 0)
					res.push({
						variant_category_id: vc.id,
						variant_id: v.id,
						name: v.name,
						qty: v.qty,
						price: v.price,
					});
			}
		}
		return res;
	};

	const prepareProducts = (vcs) => {
		let res = [];
		for (const vc of vcs) {
			for (const v of vc.products) {
				if (v.qty && v.qty > 0)
					res.push({
						variant_category_id: vc.id,
						product_id: v.id,
						name: v.name,
						qty: v.qty,
						price: v.price,
					});
			}
		}
		return res;
	};

	const prepareIngredients = (ingredients) => {
		let response = "";
		for (const ingredient of ingredients) {
			if (ingredient.removed) response += "No " + ingredient.name + "\n";
		}
		return response;
	};

	function checkDailyClosures() {
		console.log("checkDailyClosures");
		for (const p of fiscalPrinters) {
			if (!p.last_daily_closure)
				setConfirm({
					title: "Attenzione",
					message:
						"E' necessario effettuare la chisura giornaliera della stampante (" +
						p.name +
						")",
					onConfirm: async () => {
						const res = await Api.postProtected("/fiscal_printers/send_command/", {
							command: "DAILY_FISCAL_CLOUSE",
						});
						loadFiscalPrinters();
						if (res.success == 0)
							setConfirm({
								title: "Errore",
								message: res.error,
							});
					},
				});
		}
	}

	function handleSocketEvent(type, data) {
		console.log("handleSocketEvent", type);
		if (type == "settings") load_settings();
		if (type == "shops") load_shops();
		if (type == "tables") load_tables();
		if (type == "pos_categories") load_pos_categories();
		if (type == "fiscal_printers") loadFiscalPrinters();
		if (type == "menu") load_menu();
		if (type == "orders") {
			loadOrders();
			loadSells();
			load_tables();
		}
		if (type == "cloudStatus") setCloudStatus(data);
	}

	const updateOrder = async (order, request) => {
		console.log("updateOrder");
		setLoading(true);
		const id = config.mode == "local" ? order["$loki"] : order.id;
		const res = await Api.postProtected("/orders/edit/" + id + "/?debug=1", request);
		if (res.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: res.error,
			});
		}
	};

	return (
		<>
			{apiSettings && shop && (
				<Socket
					socket={socket}
					mandantId={apiSettings.mandant.id}
					onMessage={handleSocketEvent}
					shop={shop}
					config={config}
				/>
			)}
			<AppContext.Provider
				value={{
					user,
					setUser,
					shop,
					setShop,
					list,
					setList,
					menu,
					category,
					setCategory,
					cart,
					setCart,
					add_product,
					init_cart,
					delivery_types,
					setPopup,
					lists,
					shops,
					rooms,
					tables,
					setSettingsVisible,
					filteredCategories,
					setFilteredCategories,
					confirm,
					setConfirm,
					apiSettings,
					editCart2,
					config,
					setConfig,
					productionCenters,
					iframeMode,
					activeFlow,
					setActiveFlow,
					ordersListVisible,
					setOrdersListVisible,
					setTablesListVisible,
					loadOrders,
					loadSells,
					orders,
					sells,
					openOrder,
					addressPopUpVisible,
					setAddressPopUpVisible,
					addressFormPopUpVisible,
					setAddressFormPopUpVisible,
					setTablePopup,
					setSellsListVisible,
					posCategories,
					mode,
					setMode,
					categoryPos,
					setCategoryPos,
					discountsPopup,
					setDiscountsPopup,
					editingProduct,
					setEditingProduct,
					edited,
					setEdited,
					setLoading,
					updateOrder,
					setSplitPopup,
					filterDate,
					setFilterDate,
					config,
					cloudStatus,
					setOpenTablePopupVisible,
					table,
					setTable,
					setTableSelectorVisible,
					editTable,
					setInvoicePopup,
					updateOrderFromCart,
					total,
					setTotal,
					deliveryPrice,
					setDeliveryPrice,
					setInvoiceFormPopup,
					invoiceFormPopup,
					checkout,
					round,
					setRound,
					fiscalPrinters,
					setReportsPopUpVisible,
					setPosPaymentPopup,
				}}
			>
				{lock ? (
					<Lock />
				) : (
					<>
						{!user && <Login />}
						{user && cart && (
							<div className="main-container">
								<div className="left">
									<Header />
									<div className="main">
										<Sidebar />
										{category && <Products />}
									</div>
									<Footer />
								</div>
								<div className="right">
									<HeaderRight />
									<Cart />
								</div>
							</div>
						)}
						{settingsVisible && <Settings />}
						{addressPopUpVisible && <AddressPopUp />}
						{addressFormPopUpVisible && <AddressForm />}
						{ordersListVisible && (
							<OrdersList onClose={() => setOrdersListVisible(false)} />
						)}
						{tablesListVisible && <TablesList />}
						{tablePopup && <TablePopUp />}
						{tableSelectorVisible && <TableSelector order={tableSelectorVisible} />}
						{openTablePopupVisible && <OpenTablePopup />}
						{reportsPopUpVisible && <ReportsPopUp />}
						{discountsPopup && (
							<DiscountsPopup onClose={() => setDiscountsPopup(false)} />
						)}
						{editingProduct && <EditProductPopup />}
						{sellsListVisible && <SellsList />}
						{popup && <PopUp options={popup} />}
						{splitPopup && <SplitPopup options={splitPopup} />}
						{invoicePopup && <InvoicePopup />}
						{posPaymentPopup && <PosPaymentPopup order={posPaymentPopup} />}
						<Confirm />
						{loading && <Loading />}
					</>
				)}
			</AppContext.Provider>
		</>
	);
}

function getSocketUrl() {
	const hostname = window.location.hostname;
	let socketUrl;
	if (hostname.includes("localhost")) {
		let localhost_mode = false;
		if (localStorage.getItem("pos-config")) {
			const config = JSON.parse(localStorage.getItem("pos-config"));
			if (config.mode == "local") localhost_mode = true;
		}
		if (localhost_mode) socketUrl = "ws://localhost:3000";
		else socketUrl = "https://ws.yellgo.cloud:3200";
	} else if (hostname.includes(".demo.")) socketUrl = "https://ws.yellgo.cloud:3100";
	else if (hostname.includes(".devaws.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
	else if (hostname.includes(".dev.")) socketUrl = "https://ws.devaws.yellgo.idspace.it:3000";
	else socketUrl = "https://ws.yellgo.cloud";
	return socketUrl;
}

export default App;
